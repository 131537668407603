<template>
  <div class="admin-content">
    <div class="admin-content__header">
      <div class="secondary-heading">
        <i v-if="back" class="el-icon-arrow-left" @click="$router.go(-1)"></i>
        {{ title }}
      </div>

      <div>
        <slot name="actions"></slot>
      </div>
    </div>

    <div class="admin-content__body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    title: "CMS",

    props: {
      title: { type: String, required: true },
      back: { type: Boolean, default: false },
    },
  };
</script>

<style lang="scss">
  @import "@/assets/scss/main";

  .admin-content {
    max-height: calc(100vh - #{$header-height});
    width: 100%;
    overflow: auto;
    background: $background-color;

    &__header {
      padding: 1rem 2rem;
      display: flex;
      justify-content: space-between;
    }

    &__body {
      padding: 1rem 2rem;
      height: 50vh;
    }
  }
</style>
